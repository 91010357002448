import dosapan1 from "../Assets/pdpImages/dosaPan1.jpeg";
import dosapan2 from "../Assets/pdpImages/dosapan2.jpeg";
import dosapan3 from "../Assets/pdpImages/dosapan3.jpeg";
import dosapan4 from "../Assets/pdpImages/dosapan4.jpeg";

import tawa6 from "../Assets/images/plppage/tawa/tawa6.jpg";
import tawa7 from "../Assets/images/plppage/tawa/tawa7.jpg";
import tawa8 from "../Assets/images/plppage/tawa/tawa8.jpg";
import tawa9 from "../Assets/images/plppage/tawa/tawa9.jpg";

import vessel1 from "../Assets/images/plppage/vessels/vessel1.jpg";
import vessel2 from "../Assets/images/plppage/vessels/vessel2.jpg";
import vessel3 from "../Assets/images/plppage/vessels/vessel3.jpg";
import vessel4 from "../Assets/images/plppage/vessels/vessel4.jpg";
import vessel6 from "../Assets/images/plppage/vessels/vessel6.jpg";
import vessel7 from "../Assets/images/plppage/vessels/vessel7.jpg";
import vessel8 from "../Assets/images/plppage/vessels/vessel8.jpg";
import vessel9 from "../Assets/images/plppage/vessels/vessel9.jpg";

import bucket1 from "../Assets/images/plppage/bucket/bucket1.jpg";
import bucket2 from "../Assets/images/plppage/bucket/bucket2.jpg";
import bucket3 from "../Assets/images/plppage/bucket/bucket3.jpg";
import bucket4 from "../Assets/images/plppage/bucket/bucket4.jpg";
import bucket6 from "../Assets/images/plppage/bucket/bucket6.jpg";
import bucket7 from "../Assets/images/plppage/bucket/bucket7.jpg";
import bucket8 from "../Assets/images/plppage/bucket/bucket8.jpg";
import bucket9 from "../Assets/images/plppage/bucket/bucket9.jpg";

import tray1 from "../Assets/images/plppage/tray/tray1.jpg";
import tray2 from "../Assets/images/plppage/tray/tray2.jpg";
import tray3 from "../Assets/images/plppage/tray/tray3.jpg";
import tray4 from "../Assets/images/plppage/tray/tray4.jpg";
import tray6 from "../Assets/images/plppage/tray/tray6.jpg";
import tray7 from "../Assets/images/plppage/tray/tray7.jpg";
import tray8 from "../Assets/images/plppage/tray/tray8.jpg";
import tray9 from "../Assets/images/plppage/tray/tray9.jpg";

import hotpot1 from "../Assets/images/plppage/hotpot/hotpot1.jpg";
import hotpot2 from "../Assets/images/plppage/hotpot/hotpot2.jpg";
import hotpot3 from "../Assets/images/plppage/hotpot/hotpot3.jpg";
import hotpot4 from "../Assets/images/plppage/hotpot/hotpot4.jpg";
import hotpot6 from "../Assets/images/plppage/hotpot/hotpot6.jpg";
import hotpot7 from "../Assets/images/plppage/hotpot/hotpot7.jpg";
import hotpot8 from "../Assets/images/plppage/hotpot/hotpot8.jpg";
import hotpot9 from "../Assets/images/plppage/hotpot/hotpot9.jpg";

import steamer1 from "../Assets/images/plppage/steamer/steamer1.jpg";
import steamer2 from "../Assets/images/plppage/steamer/steamer2.jpg";
import steamer3 from "../Assets/images/plppage/steamer/steamer3.jpg";
import steamer4 from "../Assets/images/plppage/steamer/steamer4.jpg";
import steamer6 from "../Assets/images/plppage/steamer/steamer6.jpg";
import steamer7 from "../Assets/images/plppage/steamer/steamer7.jpg";
import steamer8 from "../Assets/images/plppage/steamer/steamer8.jpg";
import steamer9 from "../Assets/images/plppage/steamer/steamer9.jpg";

import chaffing1 from "../Assets/images/plppage/chaffing/chaffing1.jpg";
import chaffing2 from "../Assets/images/plppage/chaffing/chaffing2.jpg";
import chaffing3 from "../Assets/images/plppage/chaffing/chaffing3.jpg";
import chaffing4 from "../Assets/images/plppage/chaffing/chaffing4.jpg";
import chaffing6 from "../Assets/images/plppage/chaffing/chaffing6.jpg";
import chaffing7 from "../Assets/images/plppage/chaffing/chaffing7.jpg";
import chaffing8 from "../Assets/images/plppage/chaffing/chaffing8.jpg";
import chaffing9 from "../Assets/images/plppage/chaffing/chaffing9.jpg";

import mithai1 from "../Assets/images/plppage/mithai/mithai1.jpg";
import mithai2 from "../Assets/images/plppage/mithai/mithai2.jpg";
import mithai3 from "../Assets/images/plppage/mithai/mithai3.jpg";
import mithai4 from "../Assets/images/plppage/mithai/mithai4.jpg";
import mithai6 from "../Assets/images/plppage/mithai/mithai6.jpg";
import mithai7 from "../Assets/images/plppage/mithai/mithai7.jpg";
import mithai8 from "../Assets/images/plppage/mithai/mithai8.jpg";
import mithai9 from "../Assets/images/plppage/mithai/mithai9.jpg";

import thali1 from "../Assets/images/plppage/thali/thali1.jpg";
import thali2 from "../Assets/images/plppage/thali/thali2.jpg";
import thali3 from "../Assets/images/plppage/thali/thali3.jpg";
import thali4 from "../Assets/images/plppage/thali/thali4.jpg";
import thali6 from "../Assets/images/plppage/thali/thali6.jpg";
import thali7 from "../Assets/images/plppage/thali/thali7.jpg";
import thali8 from "../Assets/images/plppage/thali/thali8.jpg";
import thali9 from "../Assets/images/plppage/thali/thali9.jpg";

import kitchen1 from "../Assets/images/plppage/kitchen/kitchen1.jpg";
import kitchen2 from "../Assets/images/plppage/kitchen/kitchen2.jpg";
import kitchen3 from "../Assets/images/plppage/kitchen/kitchen3.jpg";
import kitchen4 from "../Assets/images/plppage/kitchen/kitchen4.jpg";
import kitchen6 from "../Assets/images/plppage/kitchen/kitchen6.jpg";
import kitchen7 from "../Assets/images/plppage/kitchen/kitchen7.jpg";
import kitchen8 from "../Assets/images/plppage/kitchen/kitchen8.jpg";
import kitchen9 from "../Assets/images/plppage/kitchen/kitchen9.jpg";

export const productVariants = [
  // SS Vessels
  {
    id: 1,
    name: "Jumbo Cooking Vessel",
    category: "SS Vessels",
    description: "Large vessel for bulk cooking, durable and efficient.",
    price: 150,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.8/5",
    images: [vessel1, vessel2],
  },
  {
    id: 2,
    name: "Mini Cooking Vessel",
    category: "SS Vessels",
    description: "Compact vessel for small meals, lightweight and sturdy.",
    price: 90,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.2/5",
    images: [vessel3, vessel4],
  },

  // SS Tawa
  {
    id: 3,
    name: "Non-Stick SS Tawa",
    category: "SS Tawa",
    description: "Perfect for dosa and chapati, with a non-stick coating.",
    price: 120,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Black",
    SetOf: "1 Piece",
    reviews: "4.6/5",
    images: [dosapan2, dosapan3],
  },
  {
    id: 4,
    name: "Premium SS Tawa",
    category: "SS Tawa",
    description: "Heavy-duty tawa for professional cooking.",
    price: 140,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.4/5",
    images: [dosapan1, dosapan4],
  },

  // SS Bucket
  {
    id: 5,
    name: "SS Milk Bucket",
    category: "SS Bucket",
    description: "Ideal for storing milk, water, and liquids.",
    price: 60,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.5/5",
    images: [bucket1, bucket2],
  },
  {
    id: 6,
    name: "SS Storage Bucket",
    category: "SS Bucket",
    description: "Multi-purpose bucket for kitchen use.",
    price: 70,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.3/5",
    images: [bucket3, bucket4],
  },

  // SS Trays
  {
    id: 7,
    name: "SS Baking Tray",
    category: "SS Trays",
    description: "Perfect for baking and roasting.",
    price: 80,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.7/5",
    images: [tray1, tray2],
  },
  {
    id: 8,
    name: "SS Serving Tray",
    category: "SS Trays",
    description: "Elegant tray for serving meals.",
    price: 55,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.3/5",
    images: [tray3, tray4],
  },

  // SS Hot Pot
  {
    id: 9,
    name: "SS Insulated Hot Pot",
    category: "SS Hot pot",
    description: "Keeps food hot for hours.",
    price: 100,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.6/5",
    images: [hotpot1, hotpot2],
  },
  {
    id: 10,
    name: "SS Compact Hot Pot",
    category: "SS Hot pot",
    description: "Ideal for single servings.",
    price: 80,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.4/5",
    images: [hotpot3, hotpot4],
  },

  // Steamers
  {
    id: 11,
    name: "SS Food Steamer",
    category: "Steamers",
    description: "Perfect for steaming vegetables and dumplings.",
    price: 90,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.8/5",
    images: [steamer1, steamer2],
  },
  {
    id: 12,
    name: "SS Idli Steamer",
    category: "Steamers",
    description: "Specialized for making idlis.",
    price: 110,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.6/5",
    images: [steamer3, steamer4],
  },

  // Chaffing Dish
  {
    id: 13,
    name: "Elegant Chaffing Dish",
    category: "Chaffing Dish",
    description: "Keeps food warm during events.",
    price: 200,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.7/5",
    images: [chaffing1, chaffing2],
  },
  {
    id: 14,
    name: "Round Chaffing Dish",
    category: "Chaffing Dish",
    description: "Stylish and functional for serving food.",
    price: 210,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "1 Piece",
    reviews: "4.5/5",
    images: [chaffing3, chaffing4],
  },

  // Mithai Items
  {
    id: 15,
    name: "SS Mithai Plate",
    category: "Mithai items",
    description: "Designed for presenting Indian sweets.",
    price: 50,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "2 Pieces",
    reviews: "4.4/5",
    images: [mithai1, mithai2],
  },
  {
    id: 16,
    name: "SS Mithai Bowl",
    category: "Mithai items",
    description: "Compact bowl for serving sweets.",
    price: 40,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "2 Pieces",
    reviews: "4.2/5",
    images: [mithai3, mithai4],
  },

  // Thali Sets
  {
    id: 17,
    name: "Traditional Thali Set",
    category: "Thali sets",
    description: "Complete dining set for Indian meals.",
    price: 150,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "6 Pieces",
    reviews: "4.9/5",
    images: [thali1, thali2],
  },
  {
    id: 18,
    name: "Compact Thali Set",
    category: "Thali sets",
    description: "Small set ideal for quick meals.",
    price: 130,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver",
    SetOf: "4 Pieces",
    reviews: "4.7/5",
    images: [thali3, thali4],
  },

  // Premium Kitchen Equipment
  {
    id: 19,
    name: "Premium SS Mixer Grinder",
    category: "Premium Kitchen Equipments",
    description: "High-performance mixer grinder for all kitchen needs.",
    price: 250,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver & Black",
    SetOf: "1 Piece",
    reviews: "4.8/5",
    images: [kitchen1, kitchen2],
  },
  {
    id: 20,
    name: "Deluxe Food Processor",
    category: "Premium Kitchen Equipments",
    description: "Multi-functional food processor for modern kitchens.",
    price: 300,
    Material: "Stainless Steel",
    Brand: "SteelCraft Industries LLP",
    Color: "Silver & Black",
    SetOf: "1 Piece",
    reviews: "4.9/5",
    images: [kitchen4, kitchen3],
  },
];

//--------------------------------------------

export const recommendedProducts = [
  // SS Vessels
  {
    id: 1,
    name: "SS Cooking Vessel",
    category: "SS Vessels",
    image: [vessel6],
    price: "150",
    rating: 4,
  },
  {
    id: 2,
    name: "SS Storage Jar",
    category: "SS Vessels",
    image: [vessel7],
    price: "130",
    rating: 5,
  },
  {
    id: 3,
    name: "SS Water Jug",
    category: "SS Vessels",
    image: [vessel8],
    price: "100",
    rating: 4,
  },
  {
    id: 4,
    name: "SS Curry Pot",
    category: "SS Vessels",
    image: [vessel9],
    price: "140",
    rating: 5,
  },

  // SS Tawa
  {
    id: 5,
    name: "Non-Stick Dosa Pan",
    category: "SS Tawa",
    image: [tawa6],
    price: "120",
    rating: 5,
  },
  {
    id: 6,
    name: "Flat Chapati Pan",
    category: "SS Tawa",
    image: [tawa7],
    price: "110",
    rating: 4,
  },
  {
    id: 7,
    name: "Deep Frying Tawa",
    category: "SS Tawa",
    image: [tawa8],
    price: "150",
    rating: 4,
  },
  {
    id: 8,
    name: "SS Griddle Plate",
    category: "SS Tawa",
    image: [tawa9],
    price: "180",
    rating: 5,
  },

  // SS Bucket
  {
    id: 9,
    name: "SS Milk Bucket",
    category: "SS Bucket",
    image: [bucket6],
    price: "80",
    rating: 4,
  },
  {
    id: 10,
    name: "SS Water Bucket",
    category: "SS Bucket",
    image: [bucket7],
    price: "90",
    rating: 4,
  },
  {
    id: 11,
    name: "SS Storage Bucket",
    category: "SS Bucket",
    image: [bucket8],
    price: "100",
    rating: 5,
  },
  {
    id: 12,
    name: "Insulated SS Bucket",
    category: "SS Bucket",
    image: [bucket9],
    price: "120",
    rating: 4,
  },

  // SS Trays
  {
    id: 13,
    name: "Multi-purpose SS Tray Set",
    category: "SS Trays",
    image: [tray6],
    price: "90",
    rating: 5,
  },
  {
    id: 14,
    name: "SS Serving Tray",
    category: "SS Trays",
    image: [tray7],
    price: "100",
    rating: 4,
  },
  {
    id: 15,
    name: "SS Baking Tray",
    category: "SS Trays",
    image: [tray8],
    price: "110",
    rating: 5,
  },
  {
    id: 16,
    name: "SS Compartment Tray",
    category: "SS Trays",
    image: [tray9],
    price: "130",
    rating: 4,
  },

  // SS Hot Pot
  {
    id: 17,
    name: "Insulated SS Hot Pot",
    category: "SS Hot pot",
    image: [hotpot6],
    price: "140",
    rating: 4,
  },
  {
    id: 18,
    name: "Large SS Hot Pot",
    category: "SS Hot pot",
    image: [hotpot7],
    price: "160",
    rating: 5,
  },
  {
    id: 19,
    name: "Compact SS Hot Pot",
    category: "SS Hot pot",
    image: [hotpot8],
    price: "120",
    rating: 4,
  },
  {
    id: 20,
    name: "Decorative SS Hot Pot",
    category: "SS Hot pot",
    image: [hotpot9],
    price: "180",
    rating: 5,
  },

  // Steamers
  {
    id: 21,
    name: "SS Steamer Pot",
    category: "Steamers",
    image: [steamer6],
    price: "180",
    rating: 4,
  },
  {
    id: 22,
    name: "Stackable SS Steamer",
    category: "Steamers",
    image: [steamer7],
    price: "200",
    rating: 5,
  },
  {
    id: 23,
    name: "Large Capacity SS Steamer",
    category: "Steamers",
    image: [steamer8],
    price: "250",
    rating: 4,
  },
  {
    id: 24,
    name: "Compact SS Steamer",
    category: "Steamers",
    image: [steamer9],
    price: "170",
    rating: 5,
  },

  // Chaffing Dish
  {
    id: 25,
    name: "Elegant Chafing Dish",
    category: "Chaffing Dish",
    image: [chaffing6],
    price: "200",
    rating: 5,
  },
  {
    id: 26,
    name: "Round Chafing Dish",
    category: "Chaffing Dish",
    image: [chaffing7],
    price: "220",
    rating: 4,
  },
  {
    id: 27,
    name: "Rectangular Chafing Dish",
    category: "Chaffing Dish",
    image: [chaffing8],
    price: "240",
    rating: 5,
  },
  {
    id: 28,
    name: "Compact Chafing Dish",
    category: "Chaffing Dish",
    image: [chaffing9],
    price: "180",
    rating: 4,
  },

  // Mithai Items
  {
    id: 29,
    name: "Traditional Mithai Maker",
    category: "Mithai items",
    image: [mithai6],
    price: "90",
    rating: 4,
  },
  {
    id: 30,
    name: "SS Sweet Mould",
    category: "Mithai items",
    image: [mithai7],
    price: "100",
    rating: 5,
  },
  {
    id: 31,
    name: "SS Laddoo Tray",
    category: "Mithai items",
    image: [mithai8],
    price: "110",
    rating: 4,
  },
  {
    id: 32,
    name: "SS Halwa Plate",
    category: "Mithai items",
    image: [mithai9],
    price: "120",
    rating: 5,
  },

  // Thali Sets
  {
    id: 33,
    name: "Classic SS Thali Set",
    category: "Thali sets",
    image: [thali6],
    price: "110",
    rating: 5,
  },
  {
    id: 34,
    name: "Large SS Thali Set",
    category: "Thali sets",
    image: [thali7],
    price: "130",
    rating: 4,
  },
  {
    id: 35,
    name: "Compact SS Thali Set",
    category: "Thali sets",
    image: [thali8],
    price: "120",
    rating: 4,
  },
  {
    id: 36,
    name: "Decorative SS Thali Set",
    category: "Thali sets",
    image: [thali9],
    price: "150",
    rating: 5,
  },

  // Premium Kitchen Equipment
  {
    id: 37,
    name: "Premium Food Processor",
    category: "Premium Kitchen Equipment",
    image: [kitchen6],
    price: "250",
    rating: 5,
  },
  {
    id: 38,
    name: "Luxury Coffee Maker",
    category: "Premium Kitchen Equipment",
    image: [kitchen7],
    price: "300",
    rating: 4,
  },
  {
    id: 39,
    name: "High-End Blender",
    category: "Premium Kitchen Equipment",
    image: [kitchen8],
    price: "400",
    rating: 5,
  },
  {
    id: 40,
    name: "Professional Mixer",
    category: "Premium Kitchen Equipment",
    image: [kitchen9],
    price: "350",
    rating: 4,
  },
];
