import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "../src/common/ScroolToTop.jsx";
import LoadingSpinner from "../src/common/LoadingSpinner.jsx";
import { productVariants } from "./StaticData/PdpData.js";

const Homepage = lazy(() => import("../src/Pages/layout/Homepage.jsx"));
const AboutUs = lazy(() => import("../src/Pages/layout/AboutUs.jsx"));
const UserDetailsForm = lazy(() => import("./common/UserDetailsForm.jsx"));
const ProductListingPage = lazy(() =>
  import("./Pages/layout/ProductListingPage.jsx")
);
const ThankYouPage = lazy(() => import("./common/ThankYou.jsx"));
const ProductDetail = lazy(() => import("./Pages/layout/ProductDetail.jsx"));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/user-details" element={<UserDetailsForm />} />
          <Route
            path="/FilterSection/:category"
            element={<ProductListingPage />}
          />
          <Route path="/ThankYouPage" element={<ThankYouPage />} />
          <Route
            path="/Product/:category/:id"
            element={<ProductDetail product={productVariants} />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
