import { useEffect } from 'react';  
import { useLocation } from 'react-router-dom';  

const ScrollToTop = () => {  
    const { pathname } = useLocation();  

    useEffect(() => {  
        window.scrollTo('_',0);  
    }, [pathname]);  

    return null;  
};  

export default ScrollToTop;